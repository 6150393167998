import { createI18n } from "vue-i18n";

const messages = {
  /* english */
  en: {
    config: {
      footer: "Footer",
      main: "Main",
      subheader: "Subheader",
      header: "Header",
      aside: "Aside",
      content: "Content",
      theme: "Theme",
      dark: "Dark",
      light: "Light",
      fixedHeader: "Fixed Header",
      desktop: "Desktop",
      tabletAndMobile: "Tablet & Mobile",
      enableFixedHeader: "Enable Fixed Header",
      menuIcon: "Menu Icon",
      fontIcon: "Font Icon",
      svgIcon: "SVG Icon",
      width: "Width",
      fluid: "Fluid",
      fixed: "Fixed",
      loader: "Loader",
      loaderType: "Loader Type",
      spinner: "Spinner",
      minimized: "Minimized",
      minimizeSecondaryPanelByDefault: "Minimize Secondary Panel By Default",
      spinnerAndMessage: "Spinner & Message",
      spinnerAndLogo: "Spinner & Logo",
      displayPageLoader: "Display Page Loader",
      displaySubheader: "Display Subheader",
      displayAside: "Display Aside",
      display: "Display",
      setAsideTheme: "Set Aside Theme",
      selectLayoutWidthType: "Select Layout Width Type",
      selectMenuIconType: "Select Menu Icon Type",
      selectPageLoaderType: "Select Page Loader Type",
      selectHeaderWidthType: "Select Header Width Type",
      preview: "Apply",
      reset: "Reset",
      generatedConfig: "Generated Config",
    },
    auth: {
      email_address: "Email address",
      mobile: "Mobile",
      whatsapp: "Whats App",
      password: "Password",
      remember_me: "Remember me",
      invalid_data: "Invalid Data",
      login: "Login",
      return_login: "return to login ?",
      submit: "Submit",
      key_code: "Key Code",
      new_password: "New Password",
      con_new_password: "Confirm New Password",
      please_wait: "Please Wait",
      sign_in_to_app: "Sign In",
    },

    nav: {
      dashboard: "Dashboard",
      statistics: "Statistics",
      invoices: "Invoices",
      categories: "Categories",
      contact: "Contact",
      paymentMethods: "Payment Methods",
      faqs: "Faqs",
      companies: "Companies",
      messages: "Messages",
      notifications: "Notifications",

      countries: "Countries",
      governments: "Governments",
      cities: "Cities",
      pieces: "Pieces",

      pages: "Pages",
      reports: "Reports",
      roles: "Roles",
      settings: "Settings",
      splashes: "Splashes",
      users: "Users",
      employees: "Employees",
      layoutBuilder: "Layout Builder",
    },

    app: {
      welcome: "Welcome at ALOSAMAI PEARL Archiving System.",
      copyright: "copyright all right reserved © 2021 -",
      gatetechs: "Gatetechs",
      usd: "USD",
      kwd: "KWD",
      egp: "EGP",
      Prev: "Prev",
      Next: "Next",

      weekly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",

      yes: "Yes",
      no: "No",
      number: "Number",
      cost: "Cost",
      detail: "Detail",
      languages: "Languages",
      choose_module: "Choose Module",
      choose_company: "Choose Company",
      module: "Module",
      percentage: "Percentage %",
      coupons: "Coupons",
      couponCount: "Coupons Code",
      side: "Side",
      choose_report: "Choose Report",
      search_by_name_or_email: "Search via name or email",
      value_type: "Value Type",
      yes_or_no: "Yes | No",
      text: "Text",
      type: "Type",

      share_links: "Share Links",

      choose_user: "Choose User",
      choose_members: "Choose Members",
      basic_info: "Basic Info",
      other: "Other",
      finish_date: "Finish Date",
      finishing: "Finishing",
      invoice: "Invoice",
      on: "ON",
      last: "Last",
      month: "Month",
      months: "Months",
      week: "Week",
      weeks: "Days",
      times: "Times",
      timesAndPrices: "Times & Prices",
      periods: "Periods",
      quick_links: "Quick Links",

      payment_terms: "Payment Terms",
      payment_method: "Payment Method",
      sendInvoice: "Send Invoice",
      sendSms: "Send SMS",
      copyLink: "Copy Link",
      sendEmail: "Send Email",
      sendWhatsapp: "Send via WhatsApp",
      twice: "Both Together",
      print_company_invoices: "Print Company Invoices",
      decline: "Decline",
      new: "Pending",
      time: "Time",
      time_from: "Time From",
      time_to: "Time To",
      period: "Period",
      minute: "Minute",
      hour: "Hour",
      day: "Day",
      expire_time: "Expire At",
      set_scheduling: "Set Scheduling",
      one_time_invoice: "One Time Invoice",
      daily: "Daily",

      price: "Price",
      question: "Question",
      answer: "Answer",
      kw: "kw",
      paid: "Paid",
      payment_success: "Success",
      payment_not_success: "Not yet",

      youtube_link: "Youtube Link",
      youtube: "Youtube",
      other_youtube_link: "Other youtube links",
      intro_youtube_link: "Intro Youtube Link",

      part_of: "Part of",
      parent_property: "Parent Property",
      partitioned: "Partitioned",
      not_partitioned: "Not Partitioned",

      bond_types: "Financial Bond Type",
      companies: "Companies",
      invoices: "Invoices",
      check_number: "Check Number",
      please_specify_reject_reason: "Please specify reject reason",
      please_specify_delayed_dateTime: "Please specify delayed date and time",
      waiting_for_customer_approve: "Waiting for customer approve",
      customer_has_approve: "Customer has approved",
      customer_has_decline: "Customer has decline",
      notify_msg: "Notification message",

      show_attachment: "Show File",
      add_new: "Add New",
      contact: "Contact",
      all: "All",
      hello: "Hello",
      profile: "Profile",
      signout: "Sign Out",
      purple: "Purple",
      dark: "Dark",
      update: "Update",
      send: "Send",
      save: "Save",
      create: "Create",
      show: "Show",
      back: "Back",
      cancel: "Cancel",
      active: "Active",
      seen: "Seen",
      unseen: "Unseen",
      inactive: "Inactive",
      from: "From",
      to: "To",
      excel: "Excel",
      csv: "CSV",
      print: "Print",
      upload_excel: "Upload XLSX",
      edit: "Edit",
      addNew: "Add New",
      addCourseToUser: "Add Course To User",
      dashboard: "Dashboard",
      search: "Search...",
      arabic: "Arabic",
      english: "English",
      balance: "Balance",

      all_rows: "All",
      showInHeader: "Show In Header",
      showInFooter: "Show In Footer",
      showInSupport: "Show In Support",

      start_date: "Start date",
      end_date: "End Date",
      start_time: "Start Time",
      end_time: "End Time",
      invoice_date: "Invoice Date",
      bond_date: "Date",
      date: "Date",
      actions: "Settings",
      status: "Status",
      sort: "Sort",
      rate: "Rate",
      star: "Star",
      stars: "Stars",
      upload: "Upload",
      upload_attachment: "Upload Attachment",
      change_upload: "Change Image",
      upload_icon: "Upload Icon",
      change_upload_icon: "Change Icon",
      change_upload_attachment: "Change Attachment",

      social: "Social Media",
      facebook: "Facebook",
      twitter: "Twitter",
      linkedin: "Linkedin",
      user_in_system: "User in system",

      export: "Export",
      bulkActions: "Bulk Actions",

      // msgs
      success_title: "Great job,",
      success_msg: "Request Completed Successfully.",
      warning_title: "Wow, man",
      warning_msg: "Slow down, ",
      error_title: "Wow-wow,",
      error_msg: "Something went wrong.",
      no_data: "No data found.",
      invalid_credentials: "Invalid Credentials",
      try_again: "Try Again",
    },

    view: {
      role: "Role",
      user: "User",
      attachemnts: "Attachments",
      bill_attachment: "Bill Attachment",
      currency: "Currency",
      pending: "PENDING",
      canceled: "CANCELED",
      paid: "PAID",
      after_one_day: "After One Day",
      after_ten_day: "After Ten Day",
      after_fifteen_day: "After Fifteen Day",
      after_thirty_day: "After Thirty Day",
      after_forty_five_day: "After Forty Five Day",
      not_expire: "Not Expire",
      custom: "Custom Choice",

      street: "Street",
      location_link: "Location Link",

      country_info: "Country Info",
      country_title: "Country Title",

      merchantID: "Merchant ID",
      service_title: "Service Title",
      service_body: "Service Body",
      key: "Key",

      invoice_info: "Invoice Info",
      invoice_key: "Invoice Key",
      payment_status: "Payment Status",
      start_date: "Start Date",
      end_date: "End Date",
      amount: "Amount",
      subject: "Subject",

      paymentMethod_info: "Payment Method Info",
      paymentMethod_title: "Payment Method",

      company: "Company",
      the_company: "Company",
      member: "Member",
      company_info: "Company Info",
      login_background: "Login Background",
      company_name: "Company Name",
      company_about: "About Company",
      details: "Details",
      notes: "Notes",

      messages_info: "Messages Info",

      notifications: "Notifications",

      time_zone: "Time Zone",
      select_elements: "Select Elements",

      page_info: "Page Info",
      page_title: "Page Title",
      page_body: "Page Description",

      category_info: "Category Info",
      category_title: "Category Title",
      category_body: "Category Description",

      setting_info: "Setting Info",
      splash_info: "Splash Info",

      username: "Username",
      email: "email",
      message_type: "Message type",
      users_type: "Users Type",
      send: "Send",
      country_code: "Country Code",
      mobile: "Mobile",
      image: "Image",
      icon: "Icon",
      location: "Location",

      message_info: "Message Info",
      supplier_info: "Supplier Info",
      supplier_name: "Supplier Name",

      permissions: "Permissions",
      role_info: "Role Info",

      role_name: "Role Name",
      password: "Password",
      users_no: "Users No.",
      name: "Name",
      title: "Title",
      body: "Description",
      addMore: "Add More",
      date_is_old: "Date in the past",
      noPermissionGranted: "No permission granted",
    },

    role: {
      add: "create",
      edit: "update",
      delete: "delete",
      view: "view",
      block: "Block",
      unblock: "Unblock",
    },

    chart: {
      total_companies: "Total Companies",
      total_users: "Total Users ",
      total_countries: "Total Countries",
      total_invoices: "Total Invoices",
      total_pending_invoices: "Total Pending Invoices",
      total_paid_invoices: "Total Paid Invoices",
      total_canceled_invoices: "Total Canceled Invoices",
      users: "App Downloads",
      invoices: "Invoices Statistics",
      pending_amount: "Pending Amount",
      canceled_amount: "Canceled Amount",
      paid_amount: "Paid Amount",
      user: "User",
      latest_invoices: "Latest Invoices",
    },

    // Month Names
    months: {
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    },

    // weekdays Name
    weekDays: {
      Sunday: "Sunday",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
    },
  },

  /* Arabic */
  ar: {
    config: {
      footer: "الفوتر",
      main: "الرئيسية",
      subheader: "الهيدر الفرعى",
      header: "الهيدر",
      aside: "القائمة الجانبية",
      content: "المحتوى",
      theme: "الشكل",
      dark: "غامق",
      light: "فاتح",
      fixedHeader: "هيدر ثابت",
      desktop: "سطح المكتب",
      tabletAndMobile: "التابلت و الجوال",
      enableFixedHeader: "تمكين الهيدر الثابت",
      menuIcon: "رمز القائمة",
      fontIcon: "أيقونة الخط",
      svgIcon: "رمز SVG",
      width: "العرض",
      fluid: "سائل",
      fixed: "ثابت",
      loader: "المحمل",
      loaderType: "نوع المحمل",
      spinner: "الدوار",
      minimized: "مصغر",
      minimizeSecondaryPanelByDefault: "تصغير اللوحة الثانوية افتراضيًا",
      spinnerAndMessage: "الدوار والرسالة",
      spinnerAndLogo: "الدوار والشعار",
      displayPageLoader: "أظهار المحمل فى كل صفحة",
      displaySubheader: "أظهار الهيدر الفرعي",
      displayAside: "أظهار القائمة الجانبية",
      display: "أظهار",
      setAsideTheme: "اختر مظهر القائمة الجانبية",
      selectLayoutWidthType: "حدد نوع عرض التخطيط",
      selectMenuIconType: "حدد نوع رمز القائمة",
      selectPageLoaderType: "حدد نوع أداة تحميل الصفحة",
      selectHeaderWidthType: "حدد نوع عرض الهيدر",
      preview: "تطبيق",
      reset: "إعادة تعيين",
      generatedConfig: "التكوين الذي تم إنشاؤه",
    },

    auth: {
      email_address: "البريد الإلكتروني",
      mobile: "رقم الجوال",
      whatsapp: "واتس اب",
      password: "كلمة المرور",
      remember_me: "تذكرني",
      invalid_data: "بيانات غير صحيحة",
      login: "تسجيل دخول",
      return_login: "رجوع لتسجيل الدخول؟",
      submit: "إرسالة",
      key_code: "الكود",
      new_password: "كلمة المرور الجديدة",
      con_new_password: "تأكيد كلمة المرور",
      please_wait: "انتظر من فضلك",
      sign_in_to_app: "تسجيل الدخول ",
    },

    nav: {
      dashboard: "لوحة التحكم",
      propertyOptions: " خصائص الوحدات",
      statistics: "الإحصائيات",
      invoices: "الفواتير",
      categories: "أصناف الوحدات",
      services: "الخدمات",
      servicecategories: "أصناف الخدمات",
      facilities: "مرافق المشروع",
      operational_services: "الخدمات التشغيلية",
      servicesuppliers: "مزودي الخدمات",
      paymentMethods: "طرق الدفع",
      coupons: "أكواد الخصم",
      faqs: "سؤال وجواب",
      companies: "الشركات",
      messages: "الرسائل",
      notifications: "الإشعارات",

      countries: "الدول",
      governments: "المحافظات",
      cities: "المدن",
      pieces: "القطع",

      pages: "الصفحات",
      reports: "التقارير",
      roles: "الصلاحيات",
      settings: "الإعدادات",
      splashes: "مقدمة التطبيق",
      users: "المدعوين",
      employees: "الموظفيين",
      contact: "التواصل",
      layoutBuilder: "منشئ التخطيط",
    },

    app: {
      welcome: "مرحبا بك في لؤلؤة العصيمي ارشيف سيستم",
      copyright: "جميع الحقوق محفوظة © 2021 -",
      gatetechs: "بوابة التقنية",
      usd: "دولار أمريكى",
      kwd: "دينار كويتى",
      egp: "جنية مصرى",
      Prev: "السابق",
      Next: "التالى",

      cost: "المبلغ",
      languages: "اللغات",
      detail: "التفاصيل",
      choose_module: "أختر القسم",
      choose_company: "أختر الشركة",
      module: "القسم",
      percentage: "النسبة ٪",
      coupons: "أكواد الخصم",
      couponCount: "عدد أكواد الخصم",
      side: "الجهه",
      choose_report: "أختر نوع التقرير",
      search_by_name_or_email: "بحث بالأسم او الإيميل",
      value_type: "نوع القيمة",
      yes_or_no: "نعم أو لا",
      text: "نص",
      yes: "نعم",
      no: "لا",
      number: "رقم",
      share_links: "روابط المشاركة",

      choose_user: "أختر المدعو",
      choose_property: "أختر الوحدة",
      choose_category: "أختر الصنف",
      choose_members: "أختر المدعوين",
      finish_date: "تاريخ الانتهاء",
      finishing: "الانتهاء",
      invoice: "فاتورة",
      on: "على",
      last: "الاخير",

      basic_info: "البيانات الرئيسية",
      other: "بيانات أخرى",

      month: "شهر",
      months: "الشهور",
      week: "سبوع",
      weeks: "أيام الأسبوع",
      times: "المواعيد",
      timesAndPrices: "المواعيد والأسعار",
      periods: "المدة",
      quick_links: "روابط سريعة",

      payment_terms: "شروط الدفع",
      payment_method: "طريقة الدفع",
      sendInvoice: "إرسال الفاتورة",
      sendSms: "إرسال رسالة نصية",
      copyLink: "نسخ الرابط",
      sendEmail: "إرسال بالبريد الإلكتروني",
      sendWhatsapp: "إرسال عبر الواتساب",
      twice: "بكلاهما معا",
      print_company_invoices: "طباعة معاملات الشركة",
      unapproved: "تم الرفض",
      new: "قيد الإنتظار",
      time: "الوقت",
      time_from: "الوقت من",
      time_to: "الوقت إلي",
      period: "المدة",
      minute: "دقيقة",
      hour: "ساعة",
      day: "يوم",
      expire_time: "ينتهى خلال",
      set_scheduling: "حدد جدول زمني",
      one_time_invoice: "فاتورة لمرة واحدة",
      daily: "يوميا",

      price: "السعر",
      question: "السؤال",
      answer: "الإجابة",
      kw: "دينار",
      paid: "الدفع",
      payment_success: "تم الدفع",
      payment_not_success: "لم يتم الدفع",

      youtube_link: "رابط اليوتيوب (الدورة)",
      youtube: "رابط اليوتيوب",
      other_youtube_link: "روابط آخري (الدورة)",
      intro_youtube_link: "رابط اليوتيوب (فيديو ترويجى)",

      part_of: "جزء من",
      parent_property: "الوحدة الأم",
      partitioned: "مجزئة",
      not_partitioned: "غير مجزئة",

      bond_types: "أنواع السندات المالية",
      companies: "الشركات",
      invoices: "الفواتير",
      check_number: "رقم الشيك",
      please_specify_reject_reason: "من فضلك حدد سبب الرفض",
      please_specify_delayed_dateTime: "من فضلك حدد الوقت وميعاد التأجيل",
      waiting_for_customer_approve: "بإنتاظار موافقة العميل",
      customer_has_approve: "تم الموافقة من العميل",
      customer_has_decline: "تم الرفض من العميل",
      notify_msg: "الإشعار",

      show_attachment: "عرض الملف",
      add_new: "إضافة جديد",
      contact: "التواصل",
      all: "الكل",
      hello: "مرحباً ",
      profile: "حسابي",
      signout: "تسجيل خروج",
      purple: "بنفسجي",
      dark: "الأسود",
      update: "تعديل",
      save: "حفظ التعديلات",
      send: "إرسال",
      create: "إنشاء",
      show: "إظهر",
      back: "رجوع",
      cancel: "إلغاء",
      active: "نشط",
      seen: "تم الإجابة",
      unseen: "رسالة جديدة",
      inactive: "غير نشط",
      from: "من",
      to: "إلي",
      excel: "تنزيل ملف XLS",
      csv: "تنزيل ملف CSV",
      print: "طباعة الجدول",
      upload_excel: "رفع ملف XLSX",
      edit: "تعديل",
      analytics: "إحصائيات",
      addNew: "أضف جديد",
      addCourseToUser: "أضف الدورة للمدعو",
      dashboard: "لوحة التحكم",
      search: "بحث...",
      search_by_name: "بحث باسم المدعو...",
      arabic: "عربي",
      english: "إنجليزي",
      balance: "الرصيد",

      all_rows: "الكل",
      showInHeader: "إظهر في أعلي الصفحة",
      showInFooter: "إظهر في أسفل الصفحة",
      showInSupport: "إظهر في قسم الدعم",

      start_cdate: "تاريخ التعاقد",
      end_cdate: "تاريخ الإنتهاء",
      start_date: "تاريخ البدأ",
      end_date: "تاريخ الإنتهاء",
      start_time: "وقت البدأ",
      end_time: "وقت الإنتهاء",
      notebookExpireDate: "تاريخ إنتهاء صلاحية الدفتر",
      invoice_date: "تاريخ الفاتورة",
      bond_date: "تاريخ السند",
      date: "التاريخ",
      actions: "الإعدادات",
      status: "الحالة",
      sort: "الترتيب",
      rate: "التقييم",
      star: "نجمة",
      stars: "النجوم",
      upload: "أرفع الصورة",
      change_upload: "تعديل الصورة",
      change_upload_icon: "تعديل الأيقونة",
      upload_icon: "أرفع الأيقونة",

      upload_attachment: "أرفع الملف",
      change_upload_attachment: "تعديل الملف",

      social: "التواصل الإجتماعي",
      facebook: "فيس بوك",
      twitter: "توتير",
      linkedin: "لينكد إن",

      user_in_system: "المدعو داخل النظام ؟",

      export: "مدير الملفات",
      bulkActions: "جملة الإعدادات",

      // msgs
      success_title: "عمل رائع,",
      success_msg: "تم تنفيذ طلبك بنجاح",
      warning_title: "أنتظر من فضلك",
      warning_msg: "",
      error_title: "خطأ",
      error_msg: "حدث خطأ من الخادم من فضلك حاول مرة آخري",
      no_data: "لا يوجد بيانات",
      invalid_credentials: "البيانات غير صحيحة",
      try_again: "أعد المحاولة",

      weekly: "أسبوعى",
      monthly: "شهرى",
      yearly: "سنوى",

      january: "يناير",
      february: "فبراير",
      march: "مارس",
      april: "ابريل",
      may: "مايو",
      june: "يونيو",
      july: "يوليو",
      august: "أغسطس",
      september: "سبتمير",
      october: "أكتوبر",
      november: "نوفمبر",
      december: "ديسمبر",

      sunday: "الأحد",
      monday: "الأثنين",
      tuesday: "الثلاثاء",
      wedensday: "الأربع",
      thursday: "الخميس",
      friday: "الجمعة",
      saturaday: "السبت",

      absent: "غائب",
      reward: "مكافئة",
      vacation: "أجازة",
    },

    view: {
      role: "صلاحية",
      user: "المدعو",
      attachemnts: "المرفقات",
      bill_attachment: "مرفقات الفاتورة",
      currency: "العملة",
      pending: "معلقة",
      canceled: "ملغية",
      paid: "مدفوعة",

      after_one_day: "بعد يوم واحد",
      after_ten_day: "بعد عشرة ايام",
      after_fifteen_day: "بعد خمسة عشر يومًا",
      after_thirty_day: "بعد ثلاثين يوم",
      after_forty_five_day: "بعد خمسة وأربعين يومًا",
      not_expire: "لا ينتهى",
      custom: "اختيار خاص",

      street: "الشارع",
      location_link: "رابط العنوان",

      country_info: "تفاصيل الدولة",
      country_title: "اسم الدولة",

      service_info: "تفاصيل الخدمة",
      merchantID: "معرف التاجر",
      service_body: "تفاصيل الخدمة",
      key: "الكود",

      invoice_info: "تفاصيل الفاتورة",
      invoice_key: "رقم الفاتورة",
      payment_status: "حالة الدفع",
      start_date: "يبدأ فى",
      end_date: "ينتهى فى",
      amount: "المبلغ",
      subject: "الموضوع",

      paymentMethod_info: "تفاصيل طريقة الدفع",
      paymentMethod_title: "طريقة الدفع",

      company: "شركة",
      the_company: "الشركة",
      member: "عضو",
      company_info: "بيانات الشركة",
      login_background: "صورة خلفية تسجيل الدخول",
      company_name: "أسم الشركة",
      company_about: "عن الشركة",
      details: "التفاصيل",
      notes: "الملاحظات",

      messages_info: "تفاصيل الرسالة",

      notifications: "الإشعارات",

      time_zone: "منطقة زمنية",
      select_elements: "اختر العناصر",

      page_info: "تفاصيل الصفحة",
      page_title: "أسم الصفحة",
      page_body: "وصف الصفحة",

      category_info: "تفاصيل الصنف",
      category_title: "أسم الصنف",
      category_body: "وصف الصنف",

      setting_info: "تفاصيل الإعدادات",
      splash_info: "تفاصيل الإسبلاش",

      username: "أسم المدعو",
      email: "البريد الاكترونى",
      message_type: "نوع الرسالة",
      users_type: "لجهة",
      send: "الارسال",
      sent: "تم الارسال",
      not_sent: "لم يتم الارسال",
      country_code: "كود الدولة",
      mobile: "رقم الجوال",
      image: "الصورة",
      icon: "الأيقونة",
      location: "الموقع",

      message_info: "تفاصيل الرسالة",
      supplier_info: "تفاصيل المزود",
      supplier_name: "أسم المزود",

      employee_info: "تفاصيل الموظفة",
      employee_name: "أسم الموظف",

      permissions: "الصلاحيات",
      role_info: "تفاصيل الصلاحية",

      type: "النوع",
      role_name: "أسم الصلاحية",
      password: "كلمة المرور",
      users_no: "عدد المدعوين",
      name: "الأسم",
      title: "العنوان",
      body: "الوصف",
      addMore: "أضف المزيد",
      date_is_old: "تاريخ الحالي اكبر من تاريخ الطلب",
      noPermissionGranted: "ليس لديك صلاحيات",
    },

    role: {
      add: "إضافة",
      edit: "تعديل",
      delete: "حذف",
      view: "مشاهدة",
      block: "تعليق الحساب",
      unblock: "إلغاء تعليق الحساب",
    },

    chart: {
      total_companies: "إجمالي الشركات",
      total_users: "إجمالي المدعوين",
      user: "مدعو",
      total_countries: "إجمالي الدول",
      total_invoices: "إجمالي الفواتير",
      total_pending_invoices: "إجمالي الفواتير المعلقة",
      total_paid_invoices: "إجمالي الفواتير المدفوعة",
      total_canceled_invoices: "إجمالي الفواتير الملغية",
      users: "إحصائيات مدعوين التطبيق",
      invoices: "إحصائيات الفواتير",
      pending_amount: "المبالغ المعلقة",
      canceled_amount: "المبالغ الملغية",
      paid_amount: "المبالغ المدفوعة",
      latest_invoices: "الفواتير الحديثة",
    },

    // Month Names
    months: {
      January: "يناير",
      February: "فبراير",
      March: "مارس",
      April: "ابريل",
      May: "مايو",
      June: "يونيو",
      July: "يوليو",
      August: "أغسطس",
      September: "سبتمبر",
      October: "أكتوبر",
      November: "نوفمر",
      December: "ديسمبر",
    },

    // weekdays Name
    weekDays: {
      Sunday: "الأحد",
      Monday: "الأثنين",
      Tuesday: "الثلاثاء",
      Wednesday: "الأربع",
      Thursday: "الخميس",
      Friday: "الجمعة",
      Saturday: "السبت",
    },
  },
};

const i18n = createI18n({
  local: "ar",
  locale: "ar",
  fallbackLocale: "ar",
  silentTranslationWarn: true,
  legacy: false,
  globalInjection: true,
  messages,
});

export default i18n;
