import { createApp, h } from "vue";
import App from "./App.vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import "@/assets/css/icons.min.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initDownloadExcel } from "@/core/plugins/download-excel";
import { initPagination } from "@/core/plugins/pagination";
import { initEditor } from "@/core/plugins/editor";
import { initPrint } from "@/core/plugins/print";
import VueHtmlToPaper from "@/core/plugins/vueHtmlToPaper";
import "@/core/plugins/izitoast";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

(window as any).domainName = "https://dashboard-send.mynurserykw.com";
// (window as any).domainName = "https://dashboard-invitation.mazadak.net";

(window as any).apiDomainName = "https://api-send.mynurserykw.com";
// (window as any).apiDomainName = "https://api-invitation.mazadak.net";

const app = createApp({
  render: () => h(App),
});

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initEditor(app);
initPagination(app);
initPrint(app);
initDownloadExcel(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(VueHtmlToPaper);

app.mount("#app");
