import Editor from "@tinymce/tinymce-vue";
import { App } from "vue";

(window as any).editor_apiKey =
  "xahz1dg338xnac8il0tkxph26xcaxqaewi3bd9cw9t4e6j7b";
(window as any).editor_menubar =
  "file edit view insert format tools table help";
(window as any).editor_plugins = [
  "advlist autolink lists link image charmap print preview anchor",
  "searchreplace visualblocks code fullscreen",
  "insertdatetime media table paste code help wordcount",
];
(window as any).editor_toolbar =
  "undo redo | formatselect | bold italic backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat | help";

export function initEditor(app: App<Element>) {
  app.component("editor", Editor);
}
