
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import {
  appGlobalConfig,
  appGlobalConfigTranslated,
} from "@/core/helpers/config";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const i18n = useI18n();
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "ar";

    const addStyleSheet = (url) => {
      var headID = document.getElementsByTagName("head")[0];
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "widget_styles";
      headID.appendChild(link);
      console.log(link, url);
      link.href = url;
    };

    onMounted(() => {
      if (i18n.locale.value == "ar") {
        addStyleSheet("/css/style.rtl.css");
      } else {
        addStyleSheet("/css/style.css");
      }

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
