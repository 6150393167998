import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

import MessagesRoutes from "@/router/modules/messages";
import AuthRoutes from "@/router/modules/auth";

let routes: Array<RouteRecordRaw> = [];
const dashboard: Array<RouteRecordRaw> = [];

routes = routes.concat(
  [
    {
      path: "/",
      redirect: "/messages",
      component: () => import("@/layout/Layout.vue"),
      children: dashboard.concat(MessagesRoutes),
    },
  ],
  [
    {
      path: "/",
      component: () => import("@/components/page-layouts/Auth.vue"),
      children: AuthRoutes,
    },
  ],
  [
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
      path: "/500",
      name: "500",
      component: () => import("@/views/crafted/authentication/Error500.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      redirect: "/404",
    },
    {
      path: "/:pathMatch(.*)",
      name: "bad-not-found",
      redirect: "/404",
    },
  ]
  //
);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
