const refs = "messages";
import { requireAuth } from "@/core/helpers/authentication";

export default [
  {
    path: "/" + refs,
    name: "list-" + refs,
    beforeEnter: requireAuth,
    component: () => import("@/views/apps/messages/list.vue"),
  },
  {
    path: "/" + refs + "/create",
    name: "create-" + refs,
    beforeEnter: requireAuth,
    component: () => import("@/views/apps/messages/send.vue"),
  },
];
